
import { Component, Mixins, Vue } from "vue-property-decorator";
import Navbar from "@/components/NavBar.vue";
import { ActionSheet } from "vant";
import { Mixin } from "@/core/mixins/mixin";

Vue.use(ActionSheet);
declare let AMap: any;

@Component({
  components: {
    Navbar,
    [ActionSheet.name]: ActionSheet,
  },
})
export default class Map extends Mixins(Mixin) {
  public name: any = "";
  public address: any = "";
  public show = false;
  public pos = {
    lng: "",
    lat: "",
  };
  get actions(): any {
    return [
      {
        name: "腾讯地图",
        href: `https://apis.map.qq.com/uri/v1/marker?marker=coord:${this.pos.lat},${this.pos.lng};title:${this.name};addr:${this.address}&referer=myapp`,
      },
      {
        name: "百度地图",
        href: `http://api.map.baidu.com/geocoder?address=${this.address}&output=html&src=webapp.baidu.openAPIdemo`,
      },
      {
        name: "高德地图",
        href: `https://uri.amap.com/marker?position=${this.pos.lng},${this.pos.lat}&this.name=${this.address}`,
      },
    ];
  }
  mounted(): void {
    this.initData();
  }
  initData(): void {
    this.name = this.$route.query.name;
    this.address = this.$route.query.address;
    window.document.title = this.name;
    this.initMap();
  }

  onSelect(item: any): void {
    location.href = item.href;
  }

  initMap(): void {
    if (
      this.ChannelModule.channel === "plat_ios" ||
      this.ChannelModule.channel === "plat_android"
    ) {
      this.jsBridge("isShowShare", "0");
    }
    const Amap = new AMap.Map("amap", {
      zoom: 16,
      center: [116.435296, 39.934393],
    });
    Amap.plugin(["AMap.ToolBar", "AMap.PlaceSearch"], () => {
      Amap.addControl(new AMap.ToolBar());
      let placeSearch = new AMap.PlaceSearch({
        pageSize: 1,
      });
      placeSearch.search(
        this.address ? this.address : this.name,
        (status: any, data: any) => {
          if (status !== "complete") return;
          let pois = data.poiList.pois;
          if (pois.length <= 0) {
            this.$toast("地址不详");
            return;
          }
          this.pos.lng = pois[0].location.lng;
          this.pos.lat = pois[0].location.lat;
          let marker = new AMap.Marker({
            content: '<div class="marker"></div>',
            position: pois[0].location,
            map: Amap,
            label: {
              content: `<div class="marker-wrapper">
              <div class="top-left">
                <h3>${this.name}</h3>
                <a href="javascript:;" class="btn-navigation">导航</a>
              </div>
              <div class="marker-bottom">${this.address}</div>
            </div>`,
            },
          });
          marker.id = pois[0].id;
          marker.name = pois[0].name;
          marker.setClickable(true);
          marker.on("click", () => {
            // 这里增加定时任务是因为立即设置show为true后，
            // action-sheet组件蒙层会自动被点击，然后立即关闭
            // 表现为action-sheet无法打开
            setTimeout(() => {
              this.show = true;
            }, 100);
          });
          Amap.setFitView();
        }
      );
    });
  }
}
